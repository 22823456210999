<template>
  <div class="q-w360 q-fit">
    <question-with-error-wrap :error="getFieldsError(['acneScars'])">
      <single-answer-question
        title="label.scarsToSoften"
        :value="acneScars"
        :options="$options.responseOptions"
        dysfunction="skin-appearance"
        @input="onFieldChange('acneScars', $event)"
      />
    </question-with-error-wrap>

    <fade-in-transition-wrap>
      <question-with-error-wrap
        v-if="hasAcneScares"
        class="q-mt24 q-mt32-md"
        :error="getFieldsError(['acneScarCauses'])"
      >
        <multi-answer-question
          id="acne-scar-causes"
          title="label.scarCauses"
          :max-answers="3"
          :value="acneScarCauses"
          :options="$options.acneScarsOptions"
          dysfunction="skin-appearance"
          @input="onFieldChange('acneScarCauses', $event)"
        />
      </question-with-error-wrap>
    </fade-in-transition-wrap>
  </div>
</template>

<script>
import FadeInTransitionWrap from '@/components/wrappers/FadeInTransitionWrap';
import QuestionWithErrorWrap from '@/modules/questionnaire/new-design-components/questions/QuestionWithErrorWrap';
import MultiAnswerQuestion from '@/modules/questionnaire/new-design-components/questions/multi-answer-question/MultiAnswerQuestion';
import SingleAnswerQuestion from '@/modules/questionnaire/new-design-components/questions/SingleAnswerQuestion';

import { stepTemplateMixin } from '@/modules/questionnaire/mixins/stepTemplateMixin';

import { ACNE_SCAR_CAUSE } from '@/modules/questionnaire/api/constants';
import { YES_NO_RESPONSE_OPTIONS } from '@/modules/questionnaire/constants/questions';

const ACNE_SCAR_CAUSE_OPTIONS = [
  { value: ACNE_SCAR_CAUSE.ACNE, text: 'scarCause.acne' },
  { value: ACNE_SCAR_CAUSE.ACCIDENT, text: 'scarCause.accident' },
  { value: ACNE_SCAR_CAUSE.SURGERY, text: 'scarCause.surgery' },
  { value: ACNE_SCAR_CAUSE.DISEASE, text: 'scarCause.disease' },
  { value: ACNE_SCAR_CAUSE.CHICKENPOX, text: 'scarCause.chickenpox' },
  { value: ACNE_SCAR_CAUSE.BURNS_AND_SCALDS, text: 'scarCause.burnsAndScalds' }
];

export default {
  name: 'ScarsTemplate',
  components: {
    FadeInTransitionWrap,
    SingleAnswerQuestion,
    MultiAnswerQuestion,
    QuestionWithErrorWrap
  },
  mixins: [stepTemplateMixin],
  acneScarsOptions: ACNE_SCAR_CAUSE_OPTIONS,
  responseOptions: YES_NO_RESPONSE_OPTIONS,
  props: {
    acneScars: {
      type: String,
      required: true
    },
    acneScarCauses: {
      type: Array,
      required: true
    },
    hasAcneScares: {
      type: Boolean,
      default: false
    }
  }
};
</script>
